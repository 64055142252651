
  import { Component, Watch } from 'vue-property-decorator'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import { BaseCustomForm } from '@/components/person/BasicCustomForm'
  import { mapActions, mapGetters } from 'vuex'
  import { Details } from '@/utils/generalInterface'
  import { AddressData } from '@/store/persons/address/addressInterfaces'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { MobileForm, FormTitle, DesktopForm, GAlert },
  methods: {
    ...mapActions('persons/address', [
      'loadRegion',
      'loadCommunes',
      'loadAddressType',
      'loadStreetType',
      'loadPropertyType',
      'createAddress',
    ]),
  },
  computed: {
    ...mapGetters('persons/address', [
      'region',
      'commune',
      'addressType',
      'streetType',
      'propertyType',
      'addDirection',
      'directionCreated',
    ]),
  },
})
  export default class PersonAddress extends BaseCustomForm {
  createAddress!: ({ address, personId }: AddressData) => Promise<void>;
  loadRegion!: () => void;
  loadCommunes!: (id: number) => void;
  loadAddressType!: (payload) => void;
  loadStreetType!: () => void;
  loadPropertyType!: () => void;
  region!: Details[];
  commune!: Details[];
  addressType!: Details[];
  streetType!: Details[];
  propertyType!: Details[];
  title = 'Dirección'
  disabled = false

  direction = {
    type: '',
    region: '',
    commune: '',
    streetType: '',
    streetName: '',
    streetNumeration: '',
    propertyType: '',
    propertyNumber: '',
  };

  @Watch('direction.region', { immediate: true })
  onRegionChange (val) {
    if (val) {
      this.direction.commune = ''
      this.loadCommunes(val)
    }
  }

  @Watch('personEntity', { immediate: true })
  onPersonTypeLoad (val) {
    if (val?.type?.name) {
      this.loadAddressType(val.type.name)
    }
  }

  async mounted () {
    this.$refs.form?.resetValidation()
    this.loadPropertyType()
    this.loadStreetType()
    this.loadRegion()
    this.loadCommunes(1)

    const { title, backup } = this

    if (backup && 'inspectionForm' in backup) {
      this.direction.type = backup.inspectionForm?.location?.name
      this.disabled = Boolean(backup.inspectionForm?.location?.name)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(this.getForm('Lead', 'lead'), title)
    }
  }

  async sendDirectionDetails (): Promise<void> {
    if (!this.$refs.form.validate()) return
    const { getFoundPerson } = this
    this.loadingForm = true
    if (getFoundPerson.id) {
      await this.createAddress({
        address: this.direction,
        personId: getFoundPerson.id,
      })
    }

    await this.closeForm()
  }

  async setDefaultAddress (idPerson: number) {
    const addresses = await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { _and: [{ id_person: { _eq: idPerson } }, { active: { _eq: true } }] },
      force: true,
    })

    const someDefault = addresses?.some(address => address.default)

    if (!someDefault && addresses.length) {
      const fields = {
        id: addresses[0].id,
        default: true,
      }
      await this.pushData({ model: 'PersonAddress', fields })
    }
  }

  @Debounce()
  async closeForm () {
    await this.setDefaultAddress(this.getFoundPerson.id)
    this.loadingForm = false
    await this.close()
  }

  get numberPropertyRequired () {
    const { direction } = this

    return direction.propertyType !== 'house' && direction.propertyType !== 'commercial_space'
  }
  }
